.custom-search {
  .ant-input-wrapper {
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.29);
      border-radius: 10px;
      height: 40px;
    }

    button {
      border: 1px solid rgba(0, 0, 0, 0.29);
      height: 30px;

      border-radius: 10px;
    }
  }
}