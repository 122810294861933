.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.body {
    width: 100%;
    height: 87vh;
    display: flex;
    padding: 0 16px;
    gap: 25px;
}

.header {
    width: 20%;
    height: 100%;
}

.card {
    background-color: rgb(255, 255, 255);
    padding: 15px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
}

.client {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    position: relative;
}

.half {
    width: 85%;
}