.input-address {
  .ant-form-item-explain-error {
    text-align: left;
  }
  position: relative;
  .ant-form-item .ant-form-item-label {
    label {
      position: absolute !important;
    }
  }
  .address-label {
    font-size: 12px;
  }
  .adress-title {
    color: rgba(20, 16, 41, 0.4);
    font-size: 14px;
  }
  .adress-label-state {
    position: absolute;
    left: 405px;
    top: 75px;
  }
  .adress-label-address {
    position: absolute;
    left: 220px;
    top: 0px;
  }
  .adress-label-country {
    position: absolute;
    left: 220px;
    top: 145px;
  }
  .adress-label-index {
    position: absolute;
    left: 405px;
    top: 145px;
  }

  .address-item-state,
  .address-item-address,
  .address-item-province,
  .address-item-index,
  .address-item-country {
    .ant-form-item-explain-error {
      position: absolute;
      font-size: 10px;
    }
  }
}

.input-title {
  color: rgba(20, 16, 41, 0.4) !important;
  font-size: 14px;
}

.input-title-item {
  // background: rgba(239, 246, 255, 0.6);
  font-size: 16px;
  // padding: 11px 16px;
  margin-bottom: 20px;
}
