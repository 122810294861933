.header {
  height: 60px;
  background-color: #f5f7fa;
}

.user-initials {
  padding: 12px;
  background-color: #953061;
  border-radius: 10px;
  color: #ffffff;
}

.footer {
  bottom: 0px;
  background-color: #f5f7fa;
}

.footer-link {
  color: #ffab00;
  transition: 0.3s;
}

.footer-link:hover {
  opacity: 0.7;
  transition: 0.3s;
  color: #ffab00;
}