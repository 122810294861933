.ant-tabs {
  &::before {
    display: none;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  /* For tabs border */
  border-color: none;
}

.ant-tabs-top > .ant-tabs-nav::before {
  /* For the line to the right and close to the tabs */
  border: none;
}

.log-tabs-container {
  margin-top: 20px;
  font-weight: 700;
  .ant-tabs-tab-active {
    color: rgb(20, 16, 41) !important;
  }
  .ant-tabs-tab {
    color: rgb(90, 97, 102);
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(20, 16, 41) !important;
  }
}

.interactive-log-graph-item {
  background: #f5f9ff;
  padding: 10px 20px;
  border-radius: 10px;
  height: 60px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
