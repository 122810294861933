.container {
    display: grid;
    grid-template-columns: 5% 55% 40%;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.containerHalf {
    display: grid;
    grid-template-columns: 7% 93%;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

.violations {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 3px;
}


.p {
    flex: 0 0 auto;
}

.wrap {
    display: grid;
    grid-template-columns: 8% 72% 15%;
    gap: 15px;
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;
}

.wrapHide {
    display: grid;
    grid-template-columns: 8% 80% 10%;
    gap: 15px;
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;
}

.hide {
    user-select: none;
    cursor: pointer;
    color: #FFAB00;
    flex-wrap: nowrap;
    flex: 0 0 auto;
}

.current {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    gap: 15px;
    font-weight: 300;
}

.currentItemActive {
    color: #fd371f;
}

.currentItemInActive {
    color: rgb(105, 206, 70);
}