.chip {
    padding: 4px;
    border-radius: 15px;
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 400;
    line-height: 20.57px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}