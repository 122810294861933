$orange: #ffab00;
$white: #ffffff;
$bg: #f5f7fa;
$bg_main: #f5f7fa;
$text: #e0e0ff;
$dark_text: #141029;
$red: #f94747;
$dark-red-accent: #ffa6a6;

$gradient_card: linear-gradient(
  135deg,
  #ffffff 0%,
  #fafbfc 51.82%,
  #f5f7fa 100%
);
$card: #eff6ff;
$dark_icon: #403e54;
$title_color: rgba(239, 246, 255);

$grey_btn: #d9d9d9;
$dark_green_accent: #95d2bc;

.dark-red-accent {
  color: $dark-red-accent;
}

.dark-green-accent {
  color: $dark_green_accent;
}
//inputs
$input-bg-color: #f7f9fd;
$input-text-color: #141029;

.orange {
  color: $orange;
}
.red {
  color: $red;
}
.orange-bg {
  background-color: $orange;
}

.orange-border {
  border: 1px solid $orange !important;
  border-radius: 8px;
}

$landing-text-color: #0d121f;
$shop-item-title: #040815;
$shop-item-description: #596780;

$bundle-bg: #1e1e1f;
$bundle-text: #1a202c;
$bundle-description: #a4a4a4;

$download-bg: #1e1e1f;

// *** Screen break-points
$xs: 575px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
