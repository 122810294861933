.footer-section-wrapper {
  padding-top: 8%;
  padding-bottom: 8%;

  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  .footer-menu-title-container {
    .logo {
      margin-bottom: 20px;
    }
    .title {
      color: $shop-item-description;
      font-size: 18px;
      font-weight: 400;
      max-width: 400px;
      font-family: "Plus Jakarta Sans", sans-serif !important;
    }
  }
  .footer-menu-container {
    .footer-menu-item-container {
      font-family: "Plus Jakarta Sans", sans-serif !important;

      .title {
        font-size: 20px;
        color: $bundle-text;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .item {
        color: $shop-item-description;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  .footer-bottom-container {
    border-top: 1px solid $shop-item-description;
    font-family: "Plus Jakarta Sans", sans-serif !important;

    padding-top: 25px;
    .footer-bottom-item {
      font-family: "Plus Jakarta Sans", sans-serif !important;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
