//INPUT STYLE
.ant-input-affix-wrapper {
  background-color: $input-bg-color;
  border: none;
  border-radius: 8px;
  min-height: 40px;
}




// SELECT STYLE
.ant-select .ant-select-selector {
  background-color: $input-bg-color !important;
  border: none !important;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  font-family: 'Ubuntu' !important;
  color: $input-text-color;
}

.select-custom .ant-select-selector {
  background-color: white !important;
}


.ant-picker {
  background-color: #f7f9fd !important;
  min-height: 40px;
  border: none;
}

.ant-picker-content {
  font-family: 'Ubuntu' !important;
}

.ant-picker-cell-inner {
  font-family: 'Ubuntu' !important;
}

.ant-picker-panel-container {
  .ant-btn {
    background-color: $orange !important;
    color: $white !important;
    font-family: 'Ubuntu' !important;
  }
}

.ant-input-password {
  span.ant-input-password-icon {
    position: absolute;
    right: 30px;
  }
}

.ant-picker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $orange !important;

  &::before {
    border: none !important;
  }
}

.ant-picker-footer .ant-picker-today-btn {
  color: $orange !important;
  font-family: 'Ubuntu' !important;
}

.ant-input {
  background-color: $input-bg-color;
  color: $input-text-color !important;
}

.ant-form-item-control-input-content {
  textarea {
    border: none;
  }
}

.ant-form-item-control-input-content {
  input {
    background-color: $input-bg-color !important;
  }
}

.ant-form-item {
  width: 100%;
  font-family: 'Ubuntu' !important;
}

.ant-form-item-explain-error {
  text-align: left;
}

.ant-form-item-explain-error {
  font-family: 'Ubuntu' !important;
  color: #ff6b00 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffab00;
  border-color: $orange;
}

.ant-checkbox-checked .ant-checkbox-inner {
  &:hover {
    background-color: #ffab00 !important;
    border-color: $orange !important;
  }
}

.custom-input-disabled {
  cursor: default !important;

  input {
    cursor: default !important;
  }
}

.custom-input-pointer {
  cursor: pointer !important;

  input {
    cursor: pointer !important;
  }
}