.header-section-wrapper {
  background-color: $bg;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  min-width: 100%;
  .ant-btn {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .ant-btn.black {
    background-color: #1a202c;
    color: $white;
    font-family: "Ubuntu";
    font-size: 12px;
    width: 100%;
    padding-top: 6px;
    margin-right: 10px;
    &:hover {
      background-color: $grey_btn;
      transition: 0.2s ease;
      color: #1a202c;
      border: 1px solid $grey_btn;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
    }
  }
}

.header-section-wrapper.common-section-wrapper {
  margin-top: 0px !important;
}

.header-menu-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0px 10%;
  .header-menu-item-container {
    font-size: 18px;
    color: $landing-text-color;
    font-weight: 600;
    margin: 0px 10px;
    cursor: pointer;
  }
}

.header-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2%;
  padding-left: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.header-buttons-container {
  display: flex;
  .ant-btn {
    border-radius: 20px;
  }
}

.header-title-container {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  text-align: center;
  margin-top: 100px;
  .title {
    color: $bundle-text;
    font-weight: 600;
    font-size: 62px;
    margin-bottom: 50px;
  }
  .subTitle {
    font-size: 24px;
    color: #6e7c92;
    margin-bottom: 50px;
  }
  .header-title-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .ant-btn {
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 20px;
      max-width: 200px;
    }
  }
}
