.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 15px;
    padding-top: 15px;
    margin-bottom: 10px;

}

.back {
    border-radius: 15px;
    background: transparent;
    border: 0px #F5F7FA solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.main {
    font-size: 26px;
    font-weight: bold;
    color: black;
    margin: 0;
}

.second {
    font-size: 16px;
    font-weight: medium;
    color: #FFAB00;
    display: flex;
    gap: 5px;
    position: relative;
    margin: 0;
}

.point {
    width: 5px;
    height: 5px;
    background: #000;
    display: block;
    border-radius: 100%;
    position: relative;
    top: 10px;
}

.headerCon {
    display: flex;
    gap: 15px;
    align-items: center;
}