.container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.card {
    display: flex;
    gap: 15px;
    position: relative;
}

.point {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    position: relative;
    font-size: 9px;
    top: 2px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 700;
    color: #141029CC;
}

.location {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #141029CC;
}

.adress {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: #141029CC;
}

.line {
    position: absolute;
    display: block;
    width: 2px;
    height: 60px;
    top: 20px;
    left: 9px;
}