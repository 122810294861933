.input-item-title {
  color: rgba(20, 16, 41, 0.4);

  font-size: 14px;
  margin-bottom: 8px;
}

.ant-input-affix-wrapper-disabled {
  background-color: #f7f9fd !important;
}

.input-container-v2 {
  position: relative !important;
  display: flex;
}

.input-item-error {
  color: red;
  position: absolute;
  bottom: -2px;
}

.input-title-item {
  // background: rgba(239, 246, 255, 0.6);
  font-size: 20px;
  font-weight: bold;
  // padding: 11px 16px;
  margin-bottom: 20px;
  color: #141029;
}

.input-title-with-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}