.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 72vh;
    overflow: hidden;
}

.body {
    display: flex;
    width: 100%;
    height: 70vh;
    gap: 15px;
}

.tabs {
    width: 40%;
    display: flex;
    height: 100%;
    overflow-y: scroll;
}

.map {
    width: 60%;
    height: 100%;
}


.info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 60px;
}


.filters {
    width: 100%;
    display: flex;
    gap: 5px;
}

.filterItem {
    background: #F4F5FF;
    padding: 6px 8px;
    gap: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: fit-content;
}

.iconItem {
    background: #F4F5FF;
    width: 32px;
    height: 32px;
    justify-content: center;
    gap: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.cardBodyItem {
    padding: 4px;
    background: #F4F5FF;
    border-radius: 6px;
    display: flex;
    gap: 5px;
    align-items: center;
    width: fit-content;
}

.cardBodyItems {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.infoBody {
    display: flex;
    flex-direction: column;
    gap: 25px;

}

.icon {
    font-size: 11px;
    color: #706E7E;
}

.cardBodyItemP {
    font-size: 11px;
    color: #706E7E;
}

.cardBodyItemPActive {
    color: #FFAB00;
}

.span {
    display: block;
}

.card {
    display: flex;
    gap: 15px;
}

.cardBody {
    display: flex;
    flex-direction: column;
}

.cardBodyTitle {
    font-weight: 500;
    font-size: 13px;
    color: #141029;
}

.cardBodyDescription {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #706E7E;
}