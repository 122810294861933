.ant-btn {
  box-shadow: none;
  min-height: 40px;
  border: none;
}

.ant-btn.orange {
  background-color: $orange;
  color: $white;
  font-family: 'Ubuntu';
  font-size: 12px;
  width: 100%;
  padding-top: 6px;

  &:hover {
    background-color: $white !important;
    transition: 0.2s ease;
    color: $orange !important;
    border: 1px solid $orange !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
  }
}

.ant-btn.black {
  background-color: $grey_btn;
  color: $white;
  font-family: "Ubuntu";
  font-size: 12px;
  width: 100%;
  padding-top: 6px;

  &:hover {
    background-color: $grey_btn;
    transition: 0.2s ease;
    color: $grey_btn;
    border: 1px solid $grey_btn;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
  }
}

.ant-btn.white {
  background-color: $white;
  border: $white 1px solid;
  color: $white;
  font-family: 'Ubuntu';
  font-size: 12px;
  width: 100%;
  padding-top: 6px;

  &:hover {
    background-color: $orange !important;
    transition: 0.2s ease;
    color: $orange !important;
    border: 1px solid $white !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
  }
}

.create-unit {
  .ant-btn {
    background-color: $orange;
    color: $white;
    font-family: 'Ubuntu';
    font-size: 12px;

    &:hover {
      background-color: $white !important;
      transition: 0.2s ease;
      color: $orange !important;
      border: 1px solid $orange !important;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
    }
  }
}

.ant-btn.white {
  background-color: $white;
  color: $orange;
  font-family: 'Ubuntu';
  font-size: 12px;
  width: 100%;
  padding-top: 6px;

  &:hover {
    background-color: $white !important;
    transition: 0.2s ease;
    color: $orange !important;
    border: 1px solid $orange !important;
    opacity: 0.7;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
  }
}

.ant-btn.whiteV2 {
  color: $orange;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.ant-btn.grey {
  background-color: $grey_btn;
  color: $white;
  font-family: 'Ubuntu';
  font-size: 12px;
  width: 100%;
  padding-top: 6px;

  &:hover {
    background-color: $dark_icon !important;
    transition: 0.2s ease;
    color: $white !important;
    border: 1px solid $grey_btn !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
  }
}

.ant-btn.hoverWhite {
  &:hover {
    border: none;
  }
}

.ant-modal-content {
  font-family: 'Ubuntu' !important;

  .ant-btn-pramary {
    color: 'Ubuntu' !important;
    background-color: $orange;
    color: $white;
  }
}

:where(.css-dev-only-do-not-override-kghr11).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #ffab00;
  opacity: 0.7;
}

:where(.css-dev-only-do-not-override-kghr11).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ffab00;
  opacity: 0.7;
  border-color: #ffab00;
  background: transparent;
}

:where(.css-dev-only-do-not-override-kghr11).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: transparent;
  border-color: transparent;
  border: 1.5px #ffab00 solid;
}