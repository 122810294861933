.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.modal {
    width: 50% !important;
}

.rangePicker {
    width: 100%;
    height: 40px;
}