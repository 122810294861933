.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.modal {
    width: 50% !important;
}

.rangePicker {
    width: 100%;
    height: 40px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.titleBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.titleBlockH1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #141029;
}

.inputsBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.inputsBlockBody {
    display: flex;
    width: 100%;
}

.inputsBlockTitle {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.inputsBlockTitleH2 {
    font-size: 16px;
    color: #141029;
    line-height: 16px;
}

.inputsBlockFileds {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.single {
    grid-column: span 2;
}

.add {
    font-weight: 500;
    font-size: 14px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    gap: 5px;
    transition: 0.3s;
}

.add:hover {
    opacity: 0.7;
}

.item {
    background-color: #f7f9fd;
    padding: 0px 11px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
}