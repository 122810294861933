.side-bar {
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  .ant-menu-inline-collapsed {
    width: 50px !important;
  }

  .ant-menu {
    background: transparent;
    border-inline-end: none !important;
  }

  .ant-menu-submenu-title {
    // background-color: $card;
    padding: 0px 16px !important;
    color: #706e7e !important;
    height: 30px !important;
    font-weight: bold;
    font-family: 'Ubuntu' !important;
  }

  .ant-menu-item {
    background-color: transparent !important;
    padding-left: 36px !important;
    color: #403e54;
  }

  .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
  }
}

.scrollable-menu {
  overflow-y: auto;
}

// .ant-menu-item[role="menuitem"] {
//   display: none !important;
// }
.ant-menu-item-icon {
  fill: #403e54;
  transition: 0.3s;
}

.ant-menu-item-icon-stroke {
  stroke: #403e54;
  transition: 0.3s;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: $orange !important;
  color: $white;
  font-size: 13px;

  .ant-menu-item-icon {
    fill: white;
    transition: 0.3s;
  }

  .ant-menu-item-icon-stroke {
    stroke: white;
    transition: 0.3s;
  }

}

:where(.css-dev-only-do-not-override-kghr11).ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-kghr11).ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: #706e7e;
}