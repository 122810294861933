.container {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 25px;
}

.header {
    background: rgba(249, 251, 255, 1);
    width: 100%;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
}

p {
    margin: 0;
}

.headerPicker {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
    gap: 10px;
}

.select {
    position: relative;
    top: -4px;
}

.headerItemsBody {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.headerItemsBodyNumber {
    color: rgba(20, 16, 41, 0.5);
}

.headerItemsBodyProcentPsitive {
    color: rgba(105, 206, 70, 1);
    display: flex;
    gap: 5px;
    align-items: center;

}

.headerItemsBodyProcentNegative {
    color: rgba(253, 55, 31, 1);
    display: flex;
    gap: 5px;
    align-items: center;
}

.headerItemsTitle {
    color: rgba(20, 16, 41, 1);
    font-size: 14px;
    font-weight: 500;

}

.headerItems {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.headerText {
    color: rgba(20, 16, 41, 1);
    font-size: 16px;
}


.dashobards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.dashobardTitle {
    color: rgba(20, 16, 41, 1);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.dashobard {
    width: 100%;
    background: rgba(249, 251, 255, 1);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dasboardBody {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.dasboardInfo {
    position: absolute;
    top: 48px;
}

.dasboardInfoNumber {
    color: rgba(20, 16, 41, 0.8);
    font-size: 19px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    text-align: center;
}

.dasboardInfoText {
    color: rgba(20, 16, 41, 0.5);
    font-size: 11px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.dashobardFooter {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.dashobardFooterItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dashobardFooterItemInfo {
    display: flex;
    gap: 5px;
    align-items: center;
}


.dashobardFooterItemColor {
    width: 19px;
    display: block;
    height: 19px;
    border-radius: 3px;
}

.dashobardFooterItemText {
    color: rgba(20, 16, 41, 1);
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}

.dashobardFooterItemNumbers {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dashobardFooterItemNumbersText {
    color: rgba(20, 16, 41, 0.5);
    font-size: 13px;
    margin: 0;
}

.rangePicker {
    background-color: white !important;
}

.bars {
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}

.bar {
    background: rgba(249, 251, 255, 1);
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.barHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.barHeaderTitle {
    color: rgba(20, 16, 41, 1);
    font-weight: 500;
    font-size: 16px;
}

.barHeaderSubTitle {
    display: flex;
    gap: 5px;
    align-items: center;
}

.barHeaderSubTitleText {
    color: rgba(20, 16, 41, 1);
    font-weight: 400;
    font-size: 14px;
}

.barHeaderSubTitleColor {
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 3px;
}

.barBody {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.barBodyBar {
    display: grid;
    grid-template-columns: 1fr 2fr 0.7fr;
    width: 100%;
    gap: 5px;
    align-items: center;
}

.customBar {
    display: flex;
    background: rgba(253, 55, 31, 1);
    width: 100%;
    border-radius: 3px;
    height: 20px;

}

.customBarFirst {
    display: block;
    background: rgba(255, 171, 0, 1);
    border-radius: 2px;
    transition: 0.3s;
    width: 1%;
}

p {
    margin: 0 !important;
}