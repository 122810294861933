body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    Ubuntu, 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: 'Ubuntu' !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Ubuntu' !important;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: 'Ubuntu' !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-family: 'Ubuntu' !important;
  }
}

.ant-col {
  position: static !important;
}

.ant-row {
  position: static !important;
}

.simple {
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.simple+.active {
  background-color: rgba(55, 73, 87, 0.4);
}

.active {
  background-color: rgba(55, 73, 87, 0.4);
  transition: background-color 0.3s ease;
}

.color-468DCE {
  background-color: #468DCE;
  transition: background-color 0.3s ease;
}

.color-FF6B00 {
  background-color: #FF6B00;
  transition: background-color 0.3s ease;
}

.color-65D140 {
  background-color: #65D140;
  transition: background-color 0.3s ease;
}

.color-706E7E {
  background-color: #706E7E;
  transition: background-color 0.3s ease;
}

.color-reen {
  background-color: green;
  transition: background-color 0.3s ease;
}

.color-ed {
  background-color: red;
  transition: background-color 0.3s ease;
}

.color-lue {
  background-color: blue;
  transition: background-color 0.3s ease;
}

.ant-select-focused {
  box-shadow: 0px 0px 0px #fff !important;

}

.ant-input-focused {
  box-shadow: 0px 0px 0px #fff !important;


}

.ant-input-outlined:hover {
  background: #f7f9fd !important;
  border-color: #fff !important;
}

.main::-webkit-input-placeholder {
  font-family: 'Ubuntu' !important;
}

.main:-moz-placeholder {
  font-family: 'Ubuntu' !important;
}

.ant-upload-list-item {
  pointer-events: none;
}

.ant-upload-list-item-actions {
  pointer-events: all;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Скрытие скроллбара для Webkit браузеров (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  display: none;
}

/* Скрытие скроллбара для Firefox */
html {
  scrollbar-width: none;
  /* или "thin" для тонкого скроллбара */
}

/* Скрытие скроллбара для IE и Edge */
body {
  -ms-overflow-style: none;
}

.sticky-important {
  position: sticky !important
}


@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import './styles/colors/colors.scss';
@import './styles/fonts/fonts.scss';
@import './styles/common/index.scss';
@import './styles/common/icons.scss';
@import './styles/common/padding.scss';
@import './styles/common/container.scss';
@import './styles/common/inputs.scss';
@import './styles/common/button.scss';
@import './styles/common/sidebar.scss';

// sign in / up

@import './styles/signin_signup/index.scss';

//access
@import './styles/pages/access.scss';

//landing page sections
@import "./styles/pages/section1.scss";
@import "./styles/pages/feature-info.scss";
@import "./styles/pages/shop.scss";
@import "./styles/pages/cart.scss";
@import "./styles/pages/cart-total.scss";
@import "./styles/pages/bundle.scss";
@import "./styles/pages/download.scss";
@import "./styles/pages/faq.scss";
@import "./styles/pages/benefit.scss";
@import "./styles/pages/header.scss";
@import "./styles/pages/footer.scss";

//inputs
@import './components/common/inputs/inputs-style.scss';
@import './components/common/doubleinput/inputs-style.scss';

// table
@import './styles/common/table.scss';
// notification
@import './styles/common/notification.scss';
//header
@import './components/header/header.scss';
@import url('https://www.gstatic.com/charts/45.1/css/core/tooltip.css');
@import url('https://www.gstatic.com/charts/45.1/css/core/animation.css');
@import url('https://www.gstatic.com/charts/45.1/css/core/tooltip.css');
@import url('https://www.gstatic.com/charts/45.1/css/util/util.css');
@import url('https://www.gstatic.com/charts/45.1/css/corechart/annotation.css');
@import url('https://www.gstatic.com/charts/45.1/css/corechart/corechart.css');
@import url('https://www.gstatic.com/charts/45.1/css/material/material.css');