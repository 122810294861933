.chat {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 50px;
    z-index: 999;
    height: 50px;
    position: absolute;
    bottom: 50px;
    right: 35px;
    border: transparent 4px solid;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    border: #FFAB00 1px solid;
    width: 25px;
    height: 25px;
    border-radius: 100%;
}


.users {
    width: 45%;
    height: 100%;
    display: grid;
    grid-template-rows: 10% 5% 85%;
    border-radius: 5px;
    background: #F5F7FA;
    position: relative;
}

.usersFilters {
    position: relative;
    z-index: 2;
    padding: 0 15px;
    display: flex;
    gap: 5px;
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: 1px;
    scrollbar-color: #000;
    -ms-overflow-style: 1px;

}

.usersFilters::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.usersFilters::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.usersFilters::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f0f0f0;
}

.usersFilters::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.usersFilters {
    scrollbar-color: #888 #f0f0f0;
    scrollbar-width: thin;
}

.usersFiltersItem {
    border-radius: 6px;
    padding: 0 6px;
    height: 22px;
    cursor: pointer;
    background: white;
    color: #706E7E;
    transition: 0.3s;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.usersFiltersItemActive {
    background: #FFAB00;
    color: white;
}

.usersHeader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    gap: 15px;
}

.usersBody {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    padding: 0 15px;
}

.menu {
    display: flex;
    gap: 15px;
    align-items: center;
}

.usersBodyItem {
    width: 100%;
    height: 67px;
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    padding: 0 15px;
    background: #F5F7FA;
    border-radius: 10px;
    justify-content: space-between;
    transition: 0.3s;
}

.usersBodyItemActive {
    background: white;
    transition: 0.3s;
}

.messagesHeader {
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 20px 0px #11122205;

}

.messages {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 10% 80% 10%;
}

.messagesBody {
    width: 100%;
    height: 100%;
    position: relative;
}

.messagesBodyInputs {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 0 15px;
    align-items: center;
    border-top: #EEFAF8 1px solid;
    gap: 25px;
}

.messagesBodyContainer {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 10px;
    overflow-x: scroll;
}

.input {
    width: 80%;
    height: 40px;
    border-radius: 12px;
    border: none;
    outline: none;
    background: #F3F6F6;
    padding: 0 0 0 12px;
}

.input:focus {
    border: none;
    outline: none;
}

.message {
    padding: 8px;
    border-radius: 0 8px 8px 8px;
    min-width: 120px;
    max-width: 300px;
    background: #F5F7FA;
    color: #000E08;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.messageCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.right {
    align-items: end;
}

.rightMessage {
    color: white;
    border-radius: 8px 0 8px 8px;
}

.my {
    border-radius: 8px 0 8px 8px;
    background-color: #FFAB00;
    color: white;
}

.messageFooter {
    display: flex;
    gap: 5px;
    color: #797C7B;
    font-weight: 400;
    font-size: 10px;
}

.messageUsername {
    color: #000E08;
    font-weight: 600;
    font-size: 14px;
}

.sendButton {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFAB00;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
}

.username {
    color: #000E08;
    font-size: 16px;
    font-weight: 500;
}

.length {
    background: #FFAB00;
    border-radius: 8.42px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 11.78px;
}

.usersLength {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #797C7B;
    font-size: 14px;
}

.usersLengthOpen {
    font-size: 12px;
}

.usersUsername {
    color: #000E08;
    font-size: 16px;
}

.usersHeaderUsername {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.usersHeaderUsernameOpen {
    font-size: 14px !important;
}

.inputSearch {
    background: white;
    outline: none;
    border: none;
    height: 40px;
    width: 100%;
    border-radius: 12px;
    padding: 0 10px;
}

.inputSearch:focus {
    outline: none;
    border: none;
}

.inputSearchCon {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
}

.usersFiltersButton {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: white;
    border-radius: 12px;
    cursor: pointer;
}

.searchIcon {
    position: absolute;
    right: 10px;
}

.usersIcon {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 31px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.messagesHeaderIcon {
    display: flex;
    gap: 15px;
    align-items: center;

}

.popup {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 15px;
    right: 15px;
    top: 70px;
    box-shadow: 0px 4px 24px 0px #00000012;
    border-radius: 10px;
    flex-direction: column;
}

.readIcon {
    width: 12px;
    height: 12px;
}

.usersFilterPopUpItem {
    display: flex;
    width: 100%;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
}

.mediaMessages {
    border-radius: 5px;

}

.fileInput {
    position: absolute;
}

.messageFile {
    min-width: 120px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.messageFilePdf {
    min-width: 120px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.pdf {
    background: #F5F7FA;
    padding: 15px;
    display: flex;
    border-radius: 0 8px 8px 8px;
    align-items: center;
    gap: 10px;
    color: #000E08;
}

.pdfMy {
    background: #FFAB00;
    border-radius: 8px 0 8px 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
}

.pdfLink {
    text-decoration: none;
}