.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: #E8EFF9;
  font-family: 'Ubuntu';
  color: #5e6874;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  //   padding: 8px 0px 8px 16px;
  border-bottom: none;
}

.ant-table-wrapper {
  .ant-table-content {
    border: 0px solid #afafaf;
    border-radius: 10px;
  }

  td {
    font-size: 13px;
    background: rgba(239, 246, 255, 0.2);
  }
}

.ant-dropdown-menu-item {
  font-size: 12px !important;
}

// .ant-table-wrapper {
//   body::-webkit-scrollbar {
//     width: 1em;
//   }

//   body::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   }

//   body::-webkit-scrollbar-thumb {
//     background-color: darkgrey;
//     outline: 1px solid slategrey;
//   }
// }

.ant-table-cell {
  font-family: 'Ubuntu' !important;
  font-size: 12px;
}

// .ant-table-body::-webkit-scrollbar {
//   width: 15px;
// }
// .ant-table-body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   border-radius: 5px;
// }
// .ant-table-bodyl::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   -webkit-box-shadow: inset 0 0 6px red;
// }
// .ant-table-body::-webkit-scrollbar {
//   width: 5px;
// }

// .ant-table-body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
// }

// .ant-table-body::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from(#eff6ff),
//     to(#eff6ff)
//   );
//   opacity: 0.4;
//   //   -webkit-box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25),
//   //     inset -2px -2px 2px rgba(0, 0, 0, 0.25);
// }

td.ant-table-cell.ant-table-selection-column {
  max-width: 50px !important;
}

.table-custom-form .ant-table-content {
  border-width: 2px 2px 0px 2px !important;
  border-radius: 10px 10px 0px 0px !important;
}


.table-custom-form-middle .ant-table-content {
  position: relative;
  top: -1px;
  border-width: 0px 2px !important;
  border-radius: 0px !important;
}

.table-custom-form-middle .ant-table-cell {
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
}

.table-custom-form-bottom .ant-table-content {
  position: relative;
  top: -2px;
  border-width: 0px 2px 2px 2px !important;
  border-radius: 0px 0px 10px 10px !important;
}

.table-custom-form-bottom .ant-table-container {
  border-inline-start: none !important;
}

.table-custom-form .ant-table-container {
  border-inline-start: none !important;
}

.table-custom-form-middle .ant-table-container {
  border-inline-start: none !important;
}

.table-custom-form-bottom .ant-table-cell {
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
}

.table-custom {
  .ant-table th.ant-table-selection-column {
    width: 50px !important;
    padding: 0 0 0 5px;
  }

  .ant-table td.ant-table-selection-column {
    width: 50px !important;
    padding: 0 0 0 5px;
  }

  .menu-option {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table {
    table-layout: fixed !important;

    .ant-checkbox-wrapper {
      display: none !important;
    }
  }
}

.logs-table {
  .table-custom {
    table {
      table-layout: fixed !important;

      .ant-checkbox-wrapper {
        display: flex !important;
        z-index: 100;
      }

      .ant-table-selection-column {
        z-index: 100;
      }
    }
  }

  .table-custom-original-log {
    .ant-table th.ant-table-selection-column {
      width: 50px !important;
    }

    .ant-table td.ant-table-selection-column {
      width: 50px !important;
    }

    .menu-option {
      cursor: pointer;
    }

    table {
      table-layout: fixed !important;

      .ant-checkbox-wrapper {
        display: none !important;
      }
    }
  }
}

.ant-table-filter-dropdown-btns {
  .ant-btn.ant-btn-link.ant-btn-sm {
    color: $orange;
  }

  .ant-btn.ant-btn-primary.ant-btn-sm {
    color: $white;
    background: $orange;
  }
}

.ant-pagination-item-active {
  background: $orange !important;
  border-color: $orange !important;

  a {
    color: $white !important;
  }
}

.roll-back-button {
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  background: transparent;
  color: rgba(0, 0, 0, 0.88);
  padding: 6px 5px;
  transition: 0.3s;
  cursor: pointer;
}

.roll-back-button:hover {

  // rgba(255, 171, 0, 1)
  border: 2px solid rgba(169, 187, 209, 1);
  // border: 2px solid rgba(255, 171, 0, 1);
}

.ant-table-tbody>tr:hover>td {
  // background: rgba(169, 187, 209, 0.2) !important;
  background: rgba(232, 239, 249, 0.5) !important;
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-1hyej8k).ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #E8EFF9;
}

.pointer-row {
  cursor: pointer;
}