.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    position: relative;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

.filters {
    display: flex;
    gap: 15px;
    align-items: center;

}

.rangeFiled {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    top: -11px;
}