.top-log-container {
  background: #f5f9ff;
  padding: 10px 20px;
  border-radius: 10px;

  .ant-picker {
    background-color: #ffffff !important;
  }
}

.top-log-item-container {
  display: flex;
  align-items: 'center';
  margin-left: 20px;
}

.top-log-item {
  margin-left: 10px;
}

.top-log-codriver {
  cursor: pointer;
  color: #ffab00;
}

.top-log-codriver:hover {
  color: #ffab00;
}

.log-bottom-panel-item {
  display: flex;
  align-items: center;
  height: 30px;
}

.log-bottom-panel-item.small-panel-item {
  height: 20px;
}

.log-bottom-panel-item-data {
  color: #374957;
  font-size: 12px;
  font-weight: 'bold';
}

.log-bottom-panel-item-header {
  color: #374957;
  font-size: 14px;
}

.log-dashboard-item {
  background: #f5f9ff;
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
}

.interactive-log-item-container-main {
  background: #f5f9ff;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.interactive-log-item-container {
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.bulk-container {
  .ant-form-item {
    margin-bottom: 0px;
  }
}