$ubuntu-font: "../../fonts/Ubuntu";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
// @font-face {
//     src: url("#{$ubuntu-font}/Ubuntu-Regular.ttf") format("ttf");
//     font-family: 'Ubuntu';
//     font-weight: 100;
// }

.ubuntu {
  font-family: 'Ubuntu' !important;
  font-weight: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/Ubuntu/icomoon.eot?bm81qm');
  src:
    url('../../fonts/Ubuntu/icomoon.eot?bm81qm#iefix') format('embedded-opentype'),
    url('../../fonts/Ubuntu/icomoon.ttf?bm81qm') format('truetype'),
    url('../../fonts/Ubuntu/icomoon.woff?bm81qm') format('woff'),
    url('../../fonts/Ubuntu/icomoon.svg?bm81qm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-bag:before {
  content: '\e900';
}

.icon-icon-key:before {
  content: '\e901';
}

.icon-icon-shield-check:before {
  content: '\e902';
}

.icon-icon-settings:before {
  content: '\e903';
}

.icon-icon-settings-sliders:before {
  content: '\e904';
}

.icon-icon-transfer:before {
  content: '\e905';
}

.icon-icon-learning:before {
  content: '\e906';
}

.icon-icon-document:before {
  content: '\e907';
}

.icon-icon-histogram:before {
  content: '\e908';
}

.icon-icon-folder:before {
  content: '\e909';
}

.icon-icon-lock:before {
  content: '\e90a';
}

.icon-icon-label:before {
  content: '\e90b';
}

.icon-icon-time-quarter-past:before {
  content: '\e90c';
}

.icon-icon-user:before {
  content: '\e90d';
}

.icon-icon-truck-side:before {
  content: '\e90e';
}

.icon-icon-apps:before {
  content: '\e90f';
}

.icon-icon-atention:before {
  content: '\e910';
}

.icon-fi-rr-briefcase:before {
  content: '\e93d';
}

.icon-fi-rr-plus:before {
  content: '\ea4c';
}

.icon-fi-rr-plus-small:before {
  content: '\ea4d';
}

.icon-fi-rr-cross-small:before {
  content: '\e986';
}

.icon-fi-rr-menu-dots:before {
  content: '\ea22';
}

.icon-fi-rr-call-outgoing:before {
  content: '\e94c';
}

.icon-fi-rr-pencil:before {
  content: '\ea3e';
}

.icon-fi-rr-eye:before {
  content: '\e9ae';
}

.icon-fi-rr-lock:before {
  content: '\ea0d';
}

.icon-fi-rr-copy:before {
  content: '\e97d';
}

.icon-fi-rr-lock:before {
  content: '\ea0d';
}

.icon-fi-rr-check:before {
  content: '\e95f';
}

.icon-fi-rr-minus-small:before {
  content: '\ea27';
}

.icon-fi-rr-comment-user:before {
  content: '\e977';
}

.icon-fi-rr-folder:before {
  content: '\e9c7';
}

.icon-fi-rr-user:before {
  content: '\eadc';
}

.icon-fi-rr-trash:before {
  content: '\eace';
}

.icon-fi-rr-upload:before {
  content: '\eada';
}

.icon-fi-rr-download:before {
  content: '\e99d';
}

.icon-fi-rr-label:before {
  content: '\e9fe';
}

.icon-fi-rr-truck-side:before {
  content: '\ead4';
}

.icon-fi-rr-calendar:before {
  content: '\e948';
}

.icon-fi-rr-smartphone:before {
  content: '\ea92';
}

.icon-fi-rr-key:before {
  content: '\e9fb';
}

.icon-fi-rr-apps:before {
  content: '\ea92';
}

.icon-fi-rr-bell-ring:before {
  content: '\e9fb';
}

@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/Ubuntu/icomoon1.eot?bm81qm');
  src:
    url('../../fonts/Ubuntu/icomoon1.eot?bm81qm#iefix') format('embedded-opentype'),
    url('../../fonts/Ubuntu/icomoon1.ttf?bm81qm') format('truetype'),
    url('../../fonts/Ubuntu/icomoon1.woff?bm81qm') format('woff'),
    url('../../fonts/Ubuntu/icomoon1.svg?bm81qm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// icomoon1

.icon-fi-rr-trailer:before {
  content: '\e911';
}

.icon-fi-rr-document-signed:before {
  content: '\e904';
}

.icon-fi-rr-home:before {
  content: '\e905';
}

.icon-fi-rr-layers .path1:before {
  content: '\e90a';
  color: #706e7e;
}

.icon-fi-rr-layers .path2:before {
  content: '\e90b';
  color: #8b8a97;
  margin-left: -1em;
}

.icon-fi-rr-layers .path3:before {
  content: '\e90c';
  color: #8b8a97;
  margin-left: -1em;
}

//<span class="icon-fi-rr-layers"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>

.icon-fi-rr-interactive .path1:before {
  content: '\e907';
  color: #706e7e;
}

.icon-fi-rr-interactive .path2:before {
  content: '\e908';
  color: #8b8a97;
  margin-left: -1em;
}

.icon-fi-rr-interactive .path3:before {
  content: '\e909';
  color: #8b8a97;
  margin-left: -1em;
}

//<span class="icon-fi-rr-interactive"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>

.icon-fi-rr-inbox:before {
  content: '\e906';
}

.icon-fi-rr-marker-time:before {
  content: '\e90d';
}

.icon-fi-rr-mode-portrait:before {
  content: '\e90e';
}

.icon-fi-rr-settings .path1:before {
  content: '\e90f';
  color: #706e7e;
}

.icon-fi-rr-settings .path2:before {
  content: '\e910';
  color: #8b8a97;
  margin-left: -1em;
}

//<span class="icon-fi-rr-settings"><span class="path1"></span><span class="path2"></span></span>

.icon-fi-rr-trailer:before {
  content: '\e911';
}

.icon-fi-rr-data-transfer:before {
  content: '\e903';
}

.icon-fi-rr-building:before {
  content: '\e902';
}

.icon-ff-rr-manage:before {
  content: '\e900';
}

.icon-fi-rr-bell-ring:before {
  content: '\e901';
}

.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal !important;
}

.ant-row.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400;
  font-style: normal !important;
}

.ant-row .jakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal !important;
}

.jakarta-bold {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: bold;
}

.ant-row.jakarta-bold {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: bold;
}

.ant-row .jakarta-bold {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 800 !important;
  font-style: bold !important;
}