$box-shadow: 0px 22px 34px rgba(0, 0, 0, 0.04);

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.hw100 {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}
