.chart {
  width: 100%;
  display: flex;
  gap: 5px;
  padding: 40px 0 0px 0;
}

.line {
  margin-top: 3px;
  width: 100%;
  height: 3px;
  display: flex;
  background: green;
  position: absolute;
  bottom: 5%;
}

.leftLabelsTop {
  display: flex;
  background: #e8eff9;
  width: 57px;
  border-radius: 7px 2px 2px 2px;
  height: 24.4%;
  margin-bottom: 1px;
  justify-content: center;
  align-items: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
}

.leftLabelsMiddle {
  display: flex;
  background: #e8eff9;
  width: 57px;
  border-radius: 2px;
  height: 24.4%;
  margin-bottom: 1px;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.leftLabelsBottom {
  display: flex;
  background: #e8eff9;
  width: 57px;
  border-radius: 2px 2px 2px 7px;
  height: 24.4%;
  margin-bottom: 1px;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.rightLabelsTop {
  display: flex;
  background: #e8eff9;
  width: 86px;
  border-radius: 2px 7px 2px 2px;
  /* height: 24.4%; */
  margin-bottom: 1px;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.rightLabelsMiddle {
  display: flex;
  background: #e8eff9;
  width: 86px;
  border-radius: 2px;
  height: 24.4%;
  margin-bottom: 1px;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.rightLabelsBottom {
  display: flex;
  background: #e8eff9;
  width: 86px;
  border-radius: 2px 2px 7px 2px;
  height: 24.4%;
  margin-bottom: 1px;
  justify-content: center;
  align-items: center;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
}

.topLabel {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: -20px;
  justify-content: space-between;
}

.point {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  z-index: 5;
  cursor: pointer;
}

.circle {
  width: 13px;
  height: 13px;
  display: flex;
  border-radius: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.pointTooltip {
  opacity: 1;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  transition: 0.3s;
}

.pointTooltip p {
  margin: 0;
  font-size: 10px;
}

.pointLine {
  width: 2px;
  position: absolute;
  display: flex;
}

.point:hover {
  background: rgba(0, 0, 0, 0.1);
}

.text {
  margin: 0;
  padding: 0;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-left: -3px;
  color: #a9bbd1;
}

.dataLabel {
  position: absolute;
  font-size: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}