.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}

.body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.filters {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.pickers {
    display: flex;
    gap: 10px;
    align-items: center;
}

.rangeFiled {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    top: -8px;
}

.rangePicker {
    width: 300px;
    height: 40px;
}

.defaultFilters {
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;
    top: 3px;
}