.app-container {
  color: $dark_text;
  min-height: 100vh;
  font-family: 'Ubuntu' !important;
  background: $bg_main;
}

.h100 {
  height: 100%;
}

.bg-main {
  background-color: $bg;
}

.bg-orange {
  background-color: $orange;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pointer {
  cursor: pointer;
}

.ant-row {
  font-family: 'Ubuntu' !important;
}

.driver-marker {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

:where(.css-dev-only-do-not-override-kghr11).ant-input-outlined:hover {
  background: $bg_main;
  background-color: $bg_main;
  opacity: 0.7;
}

:where(.css-dev-only-do-not-override-kghr11).ant-input-affix-wrapper>input.ant-input {
  background: #f7f9fd !important;
}

:where(.css-dev-only-do-not-override-kghr11).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-kghr11).ant-input-outlined:focus-within {
  background: $bg_main !important;
}